const state = { user: null };
const getters = {
  authenticatedUser: (state) => !!state.user,
  stateUser: (state) => state.user,
};

const actions = {
  async loginUser({commit}, user) { await commit("setUser", user); },
  async logoutUser({ commit }) { commit("destroyUser"); },
};

const mutations = {
  setUser(state, user) { state.user = user; },
  destroyUser(state) { state.user = null; },
};

export default { state, getters, actions, mutations};
