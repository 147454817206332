const companyApp = 'Sim Apps', nameApp = 'Vonet', versionApp = '1.0';
const authorApp = 'Sim App', developerApp = 'Woohlo Dev.';
const descriptionApp = 'Aplikasi Vonet by Sim Apps'

export function aboutApp(){
	return {
		company:companyApp, name:nameApp, version:versionApp,
		author:authorApp, developer:developerApp,
		description:descriptionApp
	}
}

export function aboutPage(isPage = {}){
	return {
		title: (isPage.title && isPage.title.length) ? `${nameApp} - ${isPage.title}` : nameApp,
		name: (isPage.slug && isPage.slug.length) ? isPage.slug : 'app',
		description:'',
	}
}