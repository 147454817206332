<template>
    <div class="nk-sidebar nk-sidebar-fixed" data-content="sidebarMenu">
        <div class="nk-sidebar-element nk-sidebar-head">
            <div class="nk-sidebar-brand">
                <router-link :to="{name:'admin.home'}" class="logo-link nk-sidebar-logo">
                    <img class="logo-dark logo-img logo-img-lg" src="@/assets/images/vonet-logo.png" alt="logo">
                </router-link>
            </div>
            <div class="nk-menu-trigger mr-n2">
                <a href="#" class="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em class="icon ni ni-arrow-left"></em></a>
            </div>
        </div>
        <div class="nk-sidebar-element">
            <div class="nk-sidebar-body" data-simplebar>
                <div class="nk-sidebar-content" style="overflow:auto;">
                    <div class="nk-sidebar-menu pt-3">
                        <ul class="nk-menu">
                            <li class="nk-menu-item" :class="[isMenu=='admin.home' ? 'active' : '']">
                                <router-link :to="{name: 'admin.home'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-dashboard-fill"></em></span>
                                    <span class="nk-menu-text">Dashboard</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-item" :class="[isMenu=='admin.member' ? 'active' : '']">
                                <router-link :to="{name: 'admin.member'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-users-fill"></em></span>
                                    <span class="nk-menu-text">Member</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-item" :class="[isMenu=='admin.source' ? 'active' : '']">
                                <router-link :to="{name: 'admin.source'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-layers-fill"></em></span>
                                    <span class="nk-menu-text">Source</span>
                                </router-link>
                            </li>
                            <li class="nk-menu-item" :class="[isMenu=='admin.transaction' ? 'active' : '']">
                                <router-link :to="{name: 'admin.transaction'}" class="nk-menu-link">
                                    <span class="nk-menu-icon"><em class="icon ni ni-cc-fill"></em></span>
                                    <span class="nk-menu-text">Transaction</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      isMenu: { type: String, default: 'admin.home'}
    }
}
</script>