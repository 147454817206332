<template>
  <div class="nk-main">
    <Sidebar v-cloak v-if="isDashboard" :isMenu="isRouteName"/>
    <div class="nk-wrap" :class="[isDashboard ? '' : 'nk-wrap-nosidebar']">
      <Header v-cloak v-if="isDashboard"/>
        <div class="nk-content" :class="[isDashboard ? '' : 'nk-content-single']">
            <div class="container-fluid p-0">
              <div class="nk-content-inner">
                  <router-view></router-view>
              </div>
            </div>
        </div>
    </div>
    <div class="overlay-nk-nav-toggle hide_element" data-target="sidebarMenu" id="overlay-sidebarMenu"></div>
  </div>
</template>

<script>
import { woohApp } from '@/helpers/woohApp'
import { aboutPage } from '@/config/env'
import Sidebar from './Sidebar';
import Header from './Header';

export default {
  computed: {
      isDashboard() {
        return this.$route.meta.isDashboard;
      },
      isRouteName(){
          return (this.$route.meta.parent) ? this.$route.meta.parent : this.$route.name;
      },
      isDetailRoute(){ return this.$route; }
  },
  components: { Sidebar, Header },
  name: 'app',
  mounted() {
    woohApp(); document.title = aboutPage().title;
  },  
};  
</script>