<template>
    <div class="nk-header nk-header-fluid nk-header-fixed is-light">
        <div class="container-fluid">
            <div class="nk-header-wrap">
                <div class="nk-menu-trigger d-xl-none ml-n1">
                    <a href="#" class="nk-nav-toggle nk-quick-nav-icon" data-target="sidebarMenu"><em class="icon ni ni-menu"></em></a>
                </div>
                <div class="nk-header-brand d-xl-none">
                    <router-link :to="{name:'admin.home'}" class="logo-link">
                        <img class="logo-dark logo-img logo-img-sm" src="@/assets/images/vonet-logo.png" alt="logo">
                    </router-link>
                </div>
                <div class="nk-header-news d-none d-xl-block">
                    <div class="nk-news-list">
                        <a class="nk-news-item" href="#">
                            <div class="nk-news-icon"><em class="icon ni ni-card-view"></em></div>
                            <div class="nk-news-text"><p>Vonet Panel V.1.0</p></div>
                        </a>
                    </div>
                </div>
                <div class="nk-header-tools">
                    <ul class="nk-quick-nav">
                        <li class="mr-n1">
                            <router-link :to="{name: 'home'}" class="nk-quick-nav-icon">
                                <div class="icon-status icon-status-na"><em class="icon ni ni-cart"></em></div>
                            </router-link>
                        </li><!-- .dropdown -->
                        <li v-if="isAdmin" class="dropdown user-dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                <div class="user-toggle">
                                    <div class="user-avatar sm">
                                        <em class="icon ni ni-user-alt"></em>
                                    </div>
                                    <div class="user-info d-none d-md-block">
                                        <div class="user-status">Administrator</div>
                                        <div class="user-name dropdown-indicator">@{{isAdmin.uname}}</div>
                                    </div>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                                <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                    <div class="user-card">
                                        <div class="user-avatar">
                                            <span><em class="icon ni ni-user-alt"></em></span>
                                        </div>
                                        <div class="user-info">
                                            <span class="lead-text">@{{isAdmin.uname}}</span>
                                            <span class="sub-text">{{ (isAdmin.email) ? isAdmin.email : isAdmin.phone }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-inner">
                                    <ul class="link-list">
                                        <li><a class="dark-switch" href="#"><em class="icon ni ni-moon"></em><span>Dark Mode</span></a></li>
                                    </ul>
                                </div>
                                <div class="dropdown-inner">
                                    <ul class="link-list">
                                        <li><a @click.prevent="processLogout" href="#"><em class="icon ni ni-signout"></em><span>Sign out</span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { confirmAlert, loadingAlert } from '@/helpers/global'
export default {
    computed: {
       ...mapGetters({isAdmin:'stateAdmin'}),
    },
    methods: {
        ...mapActions(['logoutAdmin']),
        async processLogout() {
            confirmAlert({type:'question',title:'Logout',text:'Yakin ingin keluar dashboard panel?',confirmLabel:'Iya, Keluar'}).then((result) => {
              if (result.isConfirmed) {
                loadingAlert({text:'Proses permintaan.....', timer:1000});
                this.logoutAdmin(); this.$router.push({'name':'admin.login'});
              }
            })
        },
    },
}
</script>