const state = { admin: null };
const getters = {
  authenticatedAdmin: (state) => !!state.admin,
  stateAdmin: (state) => state.admin,
};

const actions = {
  async loginAdmin({commit}, admin) { await commit("setAdmin", admin); },
  async logoutAdmin({ commit }) { commit("destroyAdmin"); },
};

const mutations = {
  setAdmin(state, admin) { state.admin = admin; },
  destroyAdmin(state) { state.admin = null; },
};

export default { state, getters, actions, mutations};
