import { createRouter, createWebHistory, useRouter } from 'vue-router'
import store from "@/config/store";
import { aboutPage } from '@/config/env'

//define a routes
const routes = [

/*
| -------------------------------------------------------------------
| ROUTE MAIN PAGE
| -------------------------------------------------------------------
*/
    {
        path: '/', alias:'/home', name: 'home',
        meta: { isMainPage:true, readyPage:true, isDashboard:false, aboutPage:aboutPage({title:'Transaction', slug:'home'})},
        component: () => import('@/views/Home/Index.vue')
    },
    {
        path: '/login', alias:'/signin', name: 'login',
        meta: { isMainPage:true, readyPage:true, isDashboard:false, authPage:true, aboutPage:aboutPage({title:'Login User', slug:'login'})},
        component: () => import('@/views/Home/Login.vue')
    },

/*
| -------------------------------------------------------------------
| ROUTE ADMIN PAGE
| -------------------------------------------------------------------
*/

    {
        path: '/admin', alias:'/admin/home', name: 'admin.home',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Admin Dashboard', slug:'admin'})
        },
        component: () => import('@/views/Admin/Home/Index.vue')
    },
    {
        path: '/admin/login', name: 'admin.login',
        meta: {
            isAdminPage:true, readyPage:true, authPage: true, isDashboard:false,
            aboutPage:aboutPage({title:'Admin Login', slug:'admin.login'})
        },
        component: () => import('@/views/Admin/Home/Login.vue')
    },

    //================== MEMBER LIST ==============//
    {
        path: '/admin/member', alias:'/admin/members', name: 'admin.member',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Admin Member', slug:'admin.member'}), parent:'admin.member'
        },
        component: () => import('@/views/Admin/Member/Index.vue')
    },
    {
        path: '/admin/member/:id', alias:'/admin/members/:id', name: 'admin.member.detail',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Admin Member Detail', slug:'admin.member.detail'}), parent:'admin.member'
        },
        component: () => import('@/views/Admin/Member/Detail.vue')
    },

    //================== SOURCE LIST ==============//
    {
        path: '/admin/source', alias:'/admin/sources', name: 'admin.source',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Admin Source', slug:'admin.source'}), parent:'admin.source'
        },
        component: () => import('@/views/Admin/Source/Index.vue')
    },
    {
        path: '/admin/source/:id', alias:'/admin/source/:id/voucher', name: 'admin.source.detail',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Source Detail', slug:'admin.source.detail'}), parent:'admin.source'
        },
        component: () => import('@/views/Admin/Source/Detail.vue')
    },
    {
        path: '/admin/source-import', alias:'/admin/sources-import', name: 'admin.source.import',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Source Import', slug:'admin.source.import'}), parent:'admin.source'
        },
        component: () => import('@/views/Admin/Source/Import.vue')
    },


    //================== VOUCHER LIST ==============//
    {
        path: '/admin/source/:source/voucher/:id', alias:'/admin/source/:source/voucher/:id/variant', name: 'admin.voucher.variant',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Voucher variant', slug:'admin.voucher.variant'}), parent:'admin.source'
        },
        component: () => import('@/views/Admin/Voucher/Variant.vue')
    },
    {
        path: '/admin/source/:source/voucher/:voucher/variant/:id', alias:'/admin/source/:source/voucher/:voucher/variant/:id/code', name: 'admin.voucher.variant.detail',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Voucher Variant Detal', slug:'admin.voucher.variant.detail'}), parent:'admin.source'
        },
        component: () => import('@/views/Admin/Voucher/VariantDetail.vue')
    },
    {
        path: '/admin/source/:source/voucher/:voucher/variant/:id/import', alias:'/admin/source/:source/voucher/:voucher/variant/:id/code-import', name: 'admin.voucher.code.import',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Voucher Code Import', slug:'admin.voucher.code.import'}), parent:'admin.source'
        },
        component: () => import('@/views/Admin/Voucher/ImportCode.vue')
    },

    //================== TRANSACTION LIST ==============//
    {
        path: '/admin/transaction', alias:'/admin/transactions', name: 'admin.transaction',
        meta: {
            isAdminPage:true, readyPage:true, requiresAuth: true, isDashboard:true,
            aboutPage:aboutPage({title:'Admin Transaction', slug:'admin.transaction'}), parent:'admin.transaction'
        },
        component: () => import('@/views/Admin/Transaction/Index.vue')
    },

/*
| -------------------------------------------------------------------
| ROUTE OTHER PAGE
| -------------------------------------------------------------------
*/
    {
        path: '/demo', alias:'/demo', name: 'demo',
        meta: { isOtherPage:true, readyPage:true, isDashboard:false, aboutPage:aboutPage({title:'Demo', slug:'demo'})},
        component: () => import('@/views/Page/Demo.vue')
    },
    {
        path: '/pagenotfound', name: 'pagenotfound',
        meta: { isOtherPage:true, readyPage:true, isDashboard:false, aboutPage:aboutPage({title:'Page 404', slug:'404'})},
        component: () => import('@/views/Page/NotFound.vue')
    },

/*
| -------------------------------------------------------------------
| END ROUTE PAGE
| -------------------------------------------------------------------
*/

]

//create router
const router = createRouter({ history: createWebHistory(), useroute: useRouter(), routes })

router.beforeEach((to, from, next) => {
    if(to.matched.some((record) => record.meta.readyPage)) {
        if(to.matched.some((record) => record.meta.isAdminPage)) {
            if(to.matched.some((record) => record.meta.requiresAuth)) {
                if (store.getters.authenticatedAdmin) { next(); return; }
                next("/admin/login");
            }else if(to.matched.some((record) => record.meta.authPage)) {
                if (store.getters.authenticatedAdmin) {  next("/admin"); return; }
                next();
            }else{ next(); }
        }else if(to.matched.some((record) => record.meta.isMainPage)) {
            if(to.matched.some((record) => record.meta.requiresAuth)) {
                if (store.getters.authenticatedUser) { next(); return; }
                next("/login");
            }else if(to.matched.some((record) => record.meta.authPage)) {
                if (store.getters.authenticatedUser) {  next("/"); return; }
                next();
            }else{ next(); }
        }else{
            if(to.matched.some((record) => record.meta.isOtherPage)) {
                next(); return;
            }else{
                next('/pagenotfound');
            }
        }
    }else{ next('/pagenotfound'); }
});

export default router