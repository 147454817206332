import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "@/stores/user";
import admin from "@/stores/admin";

// Create store
export default new Vuex.Store({
  modules: {user, admin},
  plugins: [createPersistedState()],
});
