import bsCustomFileInput from 'bs-custom-file-input'

export function initApp(){
	$(document).ready(function () {
  		bsCustomFileInput.init()
	})
}
export function woohApp(){
	$(document).on('click', '.nk-nav-compact',function() {
		var isTarget = $(this).attr('data-target'), isContent = document.querySelector('[data-content='+isTarget+']');
		isContent.classList.toggle('is-compact'); $(this).toggleClass('is-compact-active');
	})
	$(document).on('click', '.nk-nav-toggle',function() {
		var isTarget = $(this).attr('data-target'), isContent = document.querySelector('[data-content='+isTarget+']');
		isContent.classList.toggle('nk-sidebar-active'); $(this).toggleClass('toggle-active');
		$('#overlay-'+isTarget).toggleClass('hide_element');
	})
	$(document).on('click', '.overlay-nk-nav-toggle',function() {
		$('.nk-nav-toggle.toggle-active').click();
	})
	$(document).on('click', '.nk-menu-toggle',function() {
		var isTarget = $(this).attr('data-target'), isContent = document.querySelector('[data-menu='+isTarget+']');
		$('li.nk-menu-item').removeClass('active');
		var statMenu = isContent.getAttribute('is-show');
		if(statMenu=='hide'){
			isContent.classList.add('active');
		}else{
			isContent.classList.remove('active');
		}
		$('li.nk-menu-item').attr('is-show', 'hide');
		let upStatMenu = (statMenu=='hide') ? 'active' : 'hide';
		isContent.setAttribute('is-show', upStatMenu);
	})
}